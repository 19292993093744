import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom/client';
import Page from "./pages/Page";
import './index.css';
import './styles/divIcon.css';
import {CookiesProvider} from "react-cookie";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {MAINTHEME} from "./styles/themes";
import {AuthProvider} from "./components/context/AuthProvider";

const Precipitation = lazy(() => import("./pages/routes/Precipitation/Precipitation"))
const LazySettings = lazy(() => import("./pages/routes/Settings/Settings"))

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <CookiesProvider>
        <BrowserRouter>
            <ThemeProvider theme={MAINTHEME}>
                <AuthProvider>
                    <Page>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route key={'/welcome'} path={'/'} element={<Precipitation/>}/>
                                <Route key={'/precipitation'} path={'/precipitation'} element={<Precipitation/>}/>
                                <Route key={'/area'} path={'/area'} element={<></>}/>
                                <Route key={'/lightning'} path={'/lightning'} element={<></>}/>
                                <Route key={'/settings'} path={'/settings'} element={<LazySettings/>}/>
                                <Route key={'error'} path={'*'} element={<></>}/>
                            </Routes>
                        </Suspense>
                    </Page>
                </AuthProvider>
            </ThemeProvider>
        </BrowserRouter>
    </CookiesProvider>
);
