import styled from "styled-components";
import {useEffect, useRef} from "react";
import L, {DomEvent} from "leaflet";

interface DivProps {
    children: JSX.Element | JSX.Element[] | any,
    position: string,
    right?: string,
    left?: string,
    top?: string,
    bottom?: string
    width?: string
}

const Container = styled.div<DivProps>`
  z-index: 2000;

  user-select: none;
  position: ${props => props.position};
  right: ${props => props.right ? props.right : 'auto'};
  left: ${props => props.left ? props.left : 'auto'};
  bottom: ${props => props.bottom ? props.bottom : 'auto'};
  top: ${props => props.top ? props.top : 'auto'};
  width: ${props => props.width ? props.width : 'auto'};
  background: transparent;

`


function MapOverlay({...props}: DivProps) {
    const divContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divContainer.current) {
            DomEvent.disableClickPropagation(divContainer.current);
            DomEvent.disableScrollPropagation(divContainer.current);
        }
    });

    return <Container {...props} ref={divContainer}>
        {props.children}
    </Container>
}

export default MapOverlay