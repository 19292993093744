import {useMap, useMapEvents} from "react-leaflet";

interface Callbacks {
    zoom: (zoom: number) => void
    center: (lat: number, lng: number) => void
    cursor: (lat: number, lng: number) => void
    location: (lat: number, lng: number) => void
}

function MapEvents({...callback}: Callbacks) {
    const map = useMap()

    const mapEvents = useMapEvents({
        mousemove(event) {
            const cursor = event.latlng
            callback.cursor(cursor.lat, cursor.lng)
        },
        locationfound(event) {
            const location = event.latlng
            callback.location(location.lat, location.lng)
        },
        zoomend(event) {
            callback.zoom(event.target._zoom)
        },

        moveend(event) {
            const center = map.getCenter()
            callback.center(center.lat, center.lng)
        }
    })

    return <></>
}

export default MapEvents