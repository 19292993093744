import {useCookies} from "react-cookie";
import {GeoJSON, MapContainer, TileLayer} from 'react-leaflet'
import L, {LatLng} from "leaflet";
import MapEvents from "../components/MapEvents";
import {createContext, useContext, useEffect, useState} from "react";
import Logo from "../components/Logo";
import Menu from "../components/Bars/Menu/Menu";
import {useLocation} from "react-router-dom";
import Info from "../components/Bars/Info/Info";
import AuthContext from "../components/context/AuthProvider";


interface PageProps {
    children: JSX.Element | JSX.Element[]
}

function Page({children}: PageProps) {
    const currentRoute = useLocation()
    const [cookies, setCookie] = useCookies(['user_token', 'map_zoom', 'map_lat', 'map_lng', 'user_token'])
    // @ts-ignore
    const {setAuth} = useContext(AuthContext)


    const lat = cookies.map_lat ? cookies.map_lat : 49
    const lng = cookies.map_lng ? cookies.map_lng : 6
    const zoom = cookies.map_zoom ? cookies.map_zoom : 6
    const token = cookies.user_token ? cookies.user_token : ''
    const user = ''
    const password = ''

    useEffect(() => {
        setAuth({user, password, token})
    }, [token])

    const handleUserZoom = (zoom: number) => setCookie('map_zoom', zoom)
    const handleUserCenter = (lat: number, lng: number) => {
        setCookie('map_lat', lat);
        setCookie('map_lng', lng)
    }
    const handleUserCursor = (lat: number, lng: number) => {
        // setCursor(new LatLng(lat, lng))
    }
    const handleUserLocation = (lat: number, lng: number) => {
        console.log(`User ist at ${lat}|${lng}`)
    }


    return (

        <MapContainer center={new LatLng(lat, lng)}
                      zoom={zoom}
                      scrollWheelZoom={true}
                      zoomControl={false}
                      style={{height: '100vh', width: '100wh'}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MapEvents zoom={handleUserZoom}
                       center={handleUserCenter}
                       cursor={handleUserCursor}
                       location={handleUserLocation}/>


            {/*<Menu route={currentRoute.pathname}/>*/}
            {/*<Info location={cursor}/>*/}
            <Logo/>


            {children}
        </MapContainer>
    )
}

export default Page