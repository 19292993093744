import MapOverlay from "./util/MapOverlay";
import styled from "styled-components";

const gpaLogo = require('../assets/gpa_logo.png')

const Image = styled.img`
  border-radius: 5px;
  max-width: 50px;

  @media screen and ${props => props.theme.deviceSizes.mobileS} {
    max-width: 60px;
  }

  @media screen and ${props => props.theme.deviceSizes.mobileM} {
    max-width: 80px;
  }

  @media screen and ${props => props.theme.deviceSizes.mobileL} {
    max-width: 100px;
  }

  @media screen and ${props => props.theme.deviceSizes.tablet} {
    max-width: 150px;
  }
  
`

function Logo() {

    const handleOnClick = (event: any) => {
        event.stopPropagation()
    }

    return (
        <MapOverlay position={'absolute'} bottom={'5px'} left={'10px'}>
            <Image src={gpaLogo} onClick={handleOnClick}/>
        </MapOverlay>
    )
}

export default Logo